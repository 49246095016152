import React from "react";
import 'materialize-css/dist/css/materialize.min.css';
import { CardPanel, Col } from 'react-materialize';
export default function Card ({card}){

    return(
        
           <Col
            l={2}
            m={3}
            s={6}>
            <CardPanel className="white">
                <b>{card.id}</b>
                <span className={card.lucro > 0 ? 'green-text' : 'red-text' }>
                    <h5>{(card.total.toFixed(2))}</h5>
                    <h5>{card.lucro.toFixed(2)}</h5>
                    <h5>{card.totalLucro}</h5>
                    <h6 className={"blue-text"}>{card.rendimento}%</h6>
                </span>
               
            </CardPanel>
        </Col>
    );
}