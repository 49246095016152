import React, { Component, Fragment, useState } from 'react';
import './App.css';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';
import {Row,TextInput} from 'react-materialize';
import Card from './components/card';
export default function App () {
  const [total, setTotal] = useState(0);
  const [valores,setValores] = useState([{id: 1,total : 100.00,lucro: (100*0.001),'rendimento':1}]);
  const handleValueChange = (a) =>{
    
    const valor  = parseInt(document.querySelector("#valor").value);
    const meses  = document.querySelector("#meses").value;
    const rendimento  = document.querySelector("#rendimento").value;
    const mensal  = parseInt(document.querySelector("#mensal").value);

    setValores([{}]);

    let vals = [];
    let total = valor;
    let total2 = valor;
    let rendimentoTotal = parseInt(rendimento);
    let lucro = 0;
    let totalLucro = 0;
  

    for(let i = 1; i <= meses; i++){


      lucro += (total + mensal) * (rendimento/100);

      total =  (total + mensal ) + ((rendimento/100) * (total + mensal ));
      rendimentoTotal = ((total/valor)*100)-100; 
      totalLucro += (total*(rendimento / 100));
      vals.push({
        'id' : i,
        'total' : total,
        'lucro': (total*(rendimento / 100)), 
        'rendimento' : rendimentoTotal.toFixed(2) ,
        'totalLucro' : totalLucro.toFixed(2)
      });
      

    }
    
    setValores(vals);
    
  };
  return (
        <Fragment>
        <Row>
        <TextInput
          id="valor"
          label="Valor"
          s={4}
          type={"number"}
          defaultValue={100}
          onChange={handleValueChange}
        />
            
            <TextInput
              id="mensal"
              s={3}
              label="Deposito mensal"
              defaultValue={1}
              type={"number"}
              onChange={handleValueChange}
            />
            <TextInput
              id="rendimento"
              s={2}
              type={"number"}
              label="Redimento"
              defaultValue={1}
              onChange={handleValueChange}
            />
            <TextInput
              id="meses"
              s={2}
              type={"number"}
              defaultValue={1}
              label="meses"

              onChange={handleValueChange}
            />
        </Row>
        <Row>
          
             { valores.map(valor =>{
              return <Card card={valor}/>
             })}
          
        
      </Row>
      </Fragment>
      );
} 

